<template>
  <b-row>
    <b-col md="12">
      <b-input-group class="mb-1 search-input-group">
        <b-form-input class="search-input" placeholder="Ex: Dating in NYC be like, sunset over a calm lake, etc." v-model="searchQuery"></b-form-input>
        <b-form-select v-model="selectedImageType" :options="imageTypes" class="image-type-select"></b-form-select>
        <b-input-group-append>
          <b-button variant="primary" @click="searchInput" :disabled="generating" class="button-margin">
            <feather-icon size="13" icon="ZapIcon" />
            {{ generating ? 'Generating' : 'Generate' }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <!-- Swiper Component -->
      <swiper :options="swiperOptions" class="swiper-container mt-1">
        <swiper-slide v-for="(tab, index) in tabs" :key="index" class="ss-width">
          <b-button :variant="tab.active ? 'dark' : 'outline-secondary'" @click="selectTab(index)" class="tab-button">
            {{ tab.name }}
          </b-button>
        </swiper-slide>
      </swiper>
    </b-col>
  </b-row>
</template>


<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { BFormSelect } from 'bootstrap-vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from 'vue';
import { EventBus } from '@/event-bus';

export default {
  components: {
    Swiper,
    SwiperSlide,
    BFormSelect,
    ToastificationContent,
  },
  data() {
    return {
      searchQuery: '',
      selectedImageType: 'memes', // Default image type
      imageTypes: [
        { value: 'images', text: 'Images' },
        { value: 'memes', text: 'Memes' }
      ],
      tabs: [
        { name: 'Comic book', active: true },
        { name: 'Pixar/Disney Character', active: false },
        { name: 'Line art', active: false },
        { name: 'Black and White Film Noir', active: false },
        { name: 'Japanese Anime', active: false },
        { name: 'Digital/Oil Painting', active: false },
        // { name: 'Photographic', active: false },
        { name: 'Isometric Rooms', active: false },
      ],
      generating: false, 
      
      settings: {
                maxScrollbarLength: 100,
                wheelSpeed: .60
            },
      swiperOptions: {
        slidesPerView: 'auto',
        spaceBetween: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1024: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    },
                    768: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    },
                    640: {
                        slidesPerView: 'auto',
                        spaceBetween: 5
                    },
          320: {
            slidesPerView: 'auto',
            spaceBetween: 5,
          },
        },
      },
      selectedTab: 'Comic book',
      requestCount: 0, 
      maxRequests: 3, 
      isLogged: false, 
    };
  },
  created() {

this.checkUserLoggedIn();
EventBus.$on('update-generating', this.updateGenerating);

},
computed: {

        isLogg() {
            return this.$store.state.app.isLogged
    },
  },
  methods: {
    checkUserLoggedIn() {
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.$store.commit("app/SET_USER", false);

            } else {
                this.$store.commit("app/SET_USER", true);
            }

        },
        searchInput() {
      if (!this.searchQuery) {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'XIcon',
            title: 'Input Required',
            text: 'Please enter a prompt before generating.',
            variant: 'danger',
          },
        });
        return;
      }
      if (!this.isLogg && this.requestCount >= this.maxRequests) {
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'XIcon',
            title: 'Limit Reached',
            text: 'You have reached the maximum number of requests. Please log in to continue.',
            variant: 'danger',
          },
        });
        this.openLoginModal();
      } else {
        this.generating = true; 
        EventBus.$emit('generate-images', this.searchQuery, this.selectedTab, this.selectedImageType);
        if (!this.isLogg) {
          this.requestCount++;
        }
      }
    },
    updateGenerating(status) {
      this.generating = status;
    },
    selectTab(index) {
      this.tabs.forEach((tab, i) => {
        tab.active = i === index;
        if (tab.active) {
          this.selectedTab = tab.name;
        }
      });
      this.$emit('update:imageStyle', this.selectedTab); // Emit the selected tab name
    },
    openLoginModal() {
      this.$bvModal.show('modal-login');
    },
    handleLogin() {
      this.$store.commit("app/SET_USER", true);
      this.requestCount = 0; // Reset the request count on login
    },
  },
};
</script>

<style scoped>
/* Adjust styles for a more compact layout */
.search-input-group {
  margin-bottom: 0.5rem !important;
  display: flex;
  align-items: center;
}

.image-type-select {
  max-width: 100px; /* Adjust width as needed */
  margin-left: 10px; /* Add margin for better spacing */
}

.swiper-slide {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    padding: auto !important; /* Adds padding inside each slide for spacing */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.swiper-container {
    width: 100%;            /* Ensures the swiper takes full width */
    overflow-x: auto;       /* Enables horizontal scrolling for the container */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    scrollbar-width: none;  /* Hides scrollbar in Firefox */
    -ms-overflow-style: none;  /* Hides scrollbar in IE and Edge */
    margin-left: 6px !important;
    margin-right: 6px !important;
}

.swiper-container::-webkit-scrollbar {
    display: none;  /* Hides scrollbar in WebKit browsers */
}

.tab-button {
  font-size: 1rem !important;
  font-variant: Black !important;
    margin-bottom: 8px; /* Space between tags if they wrap to the next line */
    margin-top: 2px; 
    margin-left: 4px; 
    margin-right: 4px; 
    padding: 0.48571rem 0.71429rem !important;
    display: inline-flex; /* Use inline-flex to maintain inline behavior with flexbox capabilities */
    align-items: center !important;
    justify-content: center !important;
    white-space: nowrap; /* Keeps the tag text in a single line */
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
    overflow: hidden;
    border-radius: 20px; /* Rounded corners for aesthetic */
    border: 1px solid #ccc; /* Subtle border for definition */
    background-color: hsl(0, 0%, 100%); /* Background color */
    transition: all 0.2s ease-in-out; /* Smooth transition for hover effects */
}

.tab-button:hover, .tab-button.is-active {
    background-color: #0069d9; 
}

.b-button {
  border-radius: 0.25rem; /* Match button styles as per your design */
}
.ss-width {
    width: auto !important;

}
.form-control{
  border: 1px solid #212121
}
.custom-select{
  border: 1px solid #212121
}
.search-input::placeholder {
  color: rgb(141, 141, 141); /* Change this to your desired dark grey color */
}

@media (max-width: 767px) {
  .image-type-select{
    max-width: 30px;
}
.custom-select{
  border: 1px solid #212121
}
.form-control{
  border: 1px solid #212121;
}
.tab-button {
  font-size: 1rem !important;
    margin-bottom: 8px; /* Space between tags if they wrap to the next line */
    margin-top: 2px; 
    margin-left: 4px; 
    margin-right: 4px; 
    padding: 0.48571rem 0.71429rem !important;
    display: inline-flex; /* Use inline-flex to maintain inline behavior with flexbox capabilities */
    align-items: center !important;
    justify-content: center !important;
    white-space: nowrap; /* Keeps the tag text in a single line */
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
    overflow: hidden;
    border-radius: 20px; /* Rounded corners for aesthetic */
    border: 1px solid #ccc; /* Subtle border for definition */
    background-color: hsl(0, 0%, 100%); /* Background color */
    transition: all 0.2s ease-in-out; /* Smooth transition for hover effects */
}
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~swiper/css/swiper.css';
</style>
