<template>
    <div>
      <div class='main-loader' v-if='isLoading'></div>
      <div class="product-page">
        <b-container>
          <search-bar @search-input="generateImages" />
          <div v-if="prevImages.length">
          <b-row>
            <product-card 
              v-for="product in prevImages" 
              :key="product.id" 
              :product="product" 
              @toggle-like="updateLikeStatus" 
            />
          </b-row>
          <b-row>
            <!-- <b-col md="12" class="text-center mt-50">
              <div class='content-loader'>
                <b-button variant="dark" @click="loadMoreProducts">Load More</b-button>
              </div>
            </b-col> -->
          </b-row>
          </div>
          <div class="no-data mt-5 mb-5" v-else-if="initialLoadingComplete && !prevImages.length && !isLoading">
          <div class="mx-auto text-center">
            <h2>{{ noDataMessage }}</h2>
          </div>
        </div>
        </b-container>
      </div>
    </div>
  </template>
  
  <script>
  import SearchBar from './searchBar.vue'; // Ensure this matches the exact case
  import ImageGallery from './ImageGallery.vue'; // Ensure this matches the exact case
  import  productCard from './imageCard.vue';
  import { mapState, mapActions } from 'vuex';
  import { EventBus } from '@/event-bus'; 
  export default {
    components: {
      SearchBar,
      ImageGallery,
      productCard,
    },
    data() {
      return {
        isLoading: false,
        prevImages: [],
        noDataMessage: 'Enter prompt to create funny memes and beautiful images!',
        initialLoadingComplete: false,
      };
    },
    created() {
    this.checkUserLoggedIn();
    EventBus.$on('generate-images', this.generateImages);
    },
    computed: {
      ...mapState('app', {
        Banner: state => state.banner,
        FeaturedImages: state => state.featured_images,
      }),
    },

    mounted() {
    this.fetchImages();
    this.fetchFeaturedItems();
    },
    methods: {

      ...mapActions('app', [
        'fetchFeaturedItems',
        'loadFeaturedImagesFromStorage',
      ]),

      checkUserLoggedIn() {
            const userData = localStorage.getItem("userdetails")

            if (userData == null || undefined) {
                this.user_id = null

            } else {
                this.user_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
            }
        },  

    async generateImages(prompt, imageStyle, imageType) {
      // const apiKey = 'MrhrcTSrCmQ1cwgkog1sKFUDZsw=';
      const baseEndpoint = 'https://api.briks.ai';
      const endpoint = imageType === 'memes' ? `${baseEndpoint}/web/memes` : `${baseEndpoint}/web/images`;
      const topic = 'Tech News';
      const params = new URLSearchParams({ 
        prompt: prompt,
        topic: topic,
        imageStyle: imageStyle
      }); 
      const url = `${endpoint}?${params.toString()}`;
      // console.log('Request URL:', url);  // Log the URL for debugging

    // Retrieve user_id and tenant_id from local storage
  const user = JSON.parse(localStorage.getItem('userdetails'));
  const userId = user?.user_id;
  const tenant = JSON.parse(localStorage.getItem('domain_Data'));
  const tenantId = tenant?.tenant_id;

  if (!userId) {
    // console.warn('User ID is not available in local storage');
  }

      this.isLoading = true; // Start loading
      EventBus.$emit('update-generating', true); // Update generating status

      try {
        const response = await fetch(url, {
          headers: { 
            // 'x-api-key': apiKey,
            'Accept': 'text/event-stream'
          },
          redirect: 'follow' // Ensure redirects are followed
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let imageUrls = [];

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          
          const chunk = decoder.decode(value);
          const events = chunk.split('\n\n').filter(Boolean);

          for (const event of events) {
            try {
              const data = JSON.parse(event.replace(/^data: /, ''));
              if (data.success) {
                let items = [];
                if (imageType === 'memes') {
                  items = data.meme ? [data.meme] : data.memes;
                } else {
                  items = data.image ? [data.image] : data.images;
                }

                imageUrls = items.map(item => {
                  const id = item.split('/').pop().split('.').shift(); // Extract ID and remove extension
                  const imageUrl = `${item}`;
                  return {
                    id: id,
                    image_url: imageUrl,
                    prompt: prompt,
                    like_or_dislike: 'N'
                  };
                });

                // Check for duplicates before adding
                for (const newImage of imageUrls) {
                  if (!this.prevImages.some(image => image.id === newImage.id)) {
                    this.prevImages.unshift(newImage); // Add new images at the top
                    // console.log('New Image:', newImage); // Console the new image data

                    // Save the new image to Cosmos DB
                    if (userId && tenantId) {
                    await this.saveImageToDB(newImage.id, userId, tenantId, newImage.image_url, prompt, imageStyle, topic, 'user');
                  }
              }
            }
                if (data.message === "completed") {
                  // console.log("All images generated:", imageUrls);
                  this.isLoading = false;
                  EventBus.$emit('update-generating', false); // Update generating status
                }
              }
            } catch (error) {
              console.error('Error parsing SSE data:', error);
            }
          }
        }

        // console.log('Extracted image URLs:', imageUrls);

        this.isLoading = false; // End loading

      } catch (error) {
        console.error('Error in generateImages:', error);
        this.isLoading = false;
        EventBus.$emit('update-generating', false);
      }
    },

    async saveImageToDB(id, userId, tenantId, imageUrl, prompt, imageStyle, topic, creation = 'user') {
      try {
        const response = await fetch('https://api.briks.ai/api/saveImage', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id, userId, tenantId, imageUrl, prompt, imageStyle, topic, creation})
        });

        const data = await response.json();
        console.log(data);
      } catch (error) {
        console.error('Error saving image to DB:', error);
      }
    },


    async fetchImages() {
    const user = JSON.parse(localStorage.getItem('userdetails'));
    const userId = user?.user_id;

    if (!userId) {
      // console.error('User ID is not available in local storage');
      this.initialLoadingComplete = true;
      return;
    }
    this.isLoading = true; 
    try {
      const response = await fetch(`https://api.briks.ai/api/getImages?userId=${userId}`);
      const data = await response.json();
      // console.log('Fetched Images:', data);

      if (Array.isArray(data.images)) {
        this.prevImages = data.images.map(image => ({
          id: image.id,
          image_url: image.imageUrl,
          prompt: image.prompt,
          topic: image.topic,
          like_or_dislike: image.like_or_dislike,
          timestamp: image.timestamp
        }));
        this.isLoading = false; // End loading
        this.initialLoadingComplete = true;
        // console.log('Processed Images:', this.prevImages);
        this.isLoading = false; // End loading
        this.initialLoadingComplete = true;
      } else {
        console.error('Expected an array of images, but got:', data.images);
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  },
  

  async updateLikeStatus(imageId, likeStatus) {
      const user = JSON.parse(localStorage.getItem('userdetails'));
      const userId = user?.user_id;

      if (!userId) {
        this.$bvModal.show("modal-login");
                return;
            } 
      try {
        const response = await fetch('https://api.briks.ai/api/updateLikeStatus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId, imageId, likeStatus })
        });

        const data = await response.json();
        // console.log(data);

        if (data.message === 'Like status updated successfully!') {
          // Update the local state to reflect the new like status
          const image = this.prevImages.find(img => img.id === imageId);
          if (image) {
            image.like_or_dislike = likeStatus;
          }
        }
      } catch (error) {
        console.error('Error updating like status:', error);
      }
    },

      loadMoreProducts() {
        // Load more products (replace with your API call if needed)
      },
      handleIconClick(product, action) {
        // Handle icon click
      },
      toggleLike(product, status) {
        product.like_or_dislike = status;
      }
    },
  };

 
  </script>
  
  <style scoped>
  .search-input {
    border-radius: 0rem !important;
  }
  </style>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .swiper-slide {
    display: flex;
    align-items: 'auto';
    justify-content: center;
    padding: 2px !important;
    padding-bottom: 15px !important;
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}


.swiper-container {
    width: 100%;            /* Ensures the swiper takes full width */
    overflow-x: auto;       /* Enables horizontal scrolling for the container */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    scrollbar-width: none;  /* Hides scrollbar in Firefox */
    -ms-overflow-style: none;  /* Hides scrollbar in IE and Edge */
}

.swiper-container::-webkit-scrollbar {
    display: none;  /* Hides scrollbar in WebKit browsers */
}

  </style>
  